<template>
  <div class="product-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="product-block-title">
        Dashboard
      </div>
      <div class="product-block-desc">
        Дашборд включает в себя статистические данные  по каждому из проектов академии:
      </div>
      <div class="projects-list">
        <div class="projects-left">
          <div class="projects-item">
            <div class="projects-item-num">1</div>
            <div class="projects-item-text">Myn bala</div>
          </div>
          <div class="projects-item">
            <div class="projects-item-num">2</div>
            <div class="projects-item-text">Qabilet</div>
          </div>
          <div class="projects-item">
            <div class="projects-item-num">3</div>
            <div class="projects-item-text">Smart Ustaz</div>
          </div>
        </div>
        <div class="projects-right">
          <div class="projects-item">
            <div class="projects-item-num">4</div>
            <div class="projects-item-text">Онлайн школа</div>
          </div>
          <div class="projects-item">
            <div class="projects-item-num">5</div>
            <div class="projects-item-text">Медаль Елбасы</div>
          </div>
          <div class="projects-item">
            <div class="projects-item-num">6</div>
            <div class="projects-item-text">Игра Эра Независимости</div>
          </div>
        </div>
      </div>
<!--      <div class="product-img-djoon-1" :style="{ 'background-image': `url('/css/images/DJOON-1.png')` }">-->

<!--      </div>-->
      <el-image
          style="width: 100%; height: 100%" class="rounded"
          src="/css/images/products/dashboard-back1.png"
          fit="fit"></el-image>

      <el-image
          style="width: 100%; height: 100%" class="rounded"
          src="/css/images/products/dashboard-back2.png"
          fit="fit"></el-image>

      <el-image
          style="width: 100%; height: 100%" class="rounded"
          src="/css/images/products/dashboard-back3.jpg"
          fit="fit"></el-image>


    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
      skills: [
        'Изучение основ продаж',
        'Формирование УТП',
        'Работа с воронками продаж',
        'Основы построения контакта с клиентом',
        'Навыки самопрезентации',
        'Презентация продукта/услуги',
        'Работа с CRM системой',
        'Работа с системой телефонии',
        'Основы переговоров',
        'Базы работы со скриптами разговоров',
        'Основа работы с холодными звонками',
        'Работа с возражениями',
      ],
      advantages: [
        'Без учителей',
        'Без домашних заданий',
        'Без дедлайнов',
        'Без вебинаров',
      ]
    }
  },
}
</script>
<style>

</style>
